<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-27 15:02:15
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-25 09:57:58
-->
<template>
    <div class="printView">
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="item-wrap">
                    <el-image class="pic" :style="{height: size.high+'cm',width: size.wide+'cm'}"
                        :src="item.qrcode_link"
                        fit="fit"></el-image>
                </div>
                <div class="tag">{{item.model}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
          list: [],
          size: {}
        }
    },
    mounted () {

    },
    methods: {
        setValue(value,item){
            console.log(value,item);
            this.list = value;
            this.size = item;
        }
    },
}
</script>

<style scoped lang='scss'>
.printView{
    .list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #eee;
        padding-top: 30px;
        .item{
            width: 33%;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #fff;
            padding: 10px 0;
            border: 1px dashed #666;
            .item-wrap{
                .pic{
                    // width: 3cm;
                    // height: 3cm;
                    vertical-align: bottom;
                }
            }
            .tag{
                margin-top: 5px;
                font-size: 14px;
                color: #000;
                font-weight: bold;
            }
        }
    }
    .list::after{
        content: '';
        width: calc((100% - 10px)/4);
    }
}
</style>
