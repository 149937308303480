<!--
 * @Descripttion: 产品列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-27 14:11:47
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-25 10:14:58
-->
<template>
    <div class="productList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">产品列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="select-item">
                        <el-select filterable clearable v-model="type_id" 
                            placeholder="请选择系列筛选" @change="clickTypeChange">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.type_id"
                                :label="item.name"
                                :value="item.type_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select filterable clearable v-model="size_id" 
                            placeholder="请选择尺寸筛选" @change="clickSizeChange">
                            <el-option
                                v-for="item in sizeOptions"
                                :key="item.size_id"
                                :label="item.name"
                                :value="item.size_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select filterable clearable v-model="scene_id" 
                            placeholder="请选择使用场景筛选" @change="clickSceneChange">
                            <el-option
                                v-for="item in sceneOptions"
                                :key="item.scene_id"
                                :label="item.name"
                                :value="item.scene_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right" >
                    <el-button class="btns" @click="clickSelect" type="primary">选中打印产品({{selectTotal}})</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table ref="tableData" :data="tableData" border style="width: 100%" :row-key="getRowKeys" @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="selection" width="55" :reserve-selection="true"></el-table-column>
                    <el-table-column align="center" prop="contents" label="产品图片" width="140" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <img :src="scope.row.contents[0]" height="50" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="产品名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="model" label="产品型号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="type_transform" label="系列" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="size_transform" label="规格"></el-table-column>
                    <el-table-column align="center" prop="space_transform" label="场景" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="15"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="main-body" id="printer">
            <printView ref="printView"></printView>
        </div>
        <el-dialog
            :before-close="handleClose" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div class="title">
                <el-button :loading="btnLoading" class="btns" v-print="printer" 
                    type="primary">{{btnLoading?'请稍等...':'打印'}}</el-button>
                <div class="setting-dimensions">
                    <div class="sd-title">尺寸设置:</div>
                    <div class="sd-item">
                        <div class="uni">宽</div>
                        <el-select v-model="size.wide" 
                            placeholder="宽" @change="input1">
                            <el-option
                                v-for="item in wideOptions"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <div class="unit">cm</div>
                    </div>
                    <div class="tag">x</div>
                    <div class="sd-item">
                        <div class="uni">高</div>
                        <el-select v-model="size.high" 
                            placeholder="宽" @change="input2">
                            <el-option
                                v-for="item in highOptions"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <div class="unit">cm</div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="table-wrap" v-loading="editLoading">
                <el-table :data="tableProduct" border style="width: 100%">
                    <el-table-column align="center" prop="contents" label="产品图片" width="140" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <img :src="scope.row.contents[0]" height="50" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="产品名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="model" label="产品型号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="type_transform" label="系列" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="size_transform" label="规格"></el-table-column>
                    <el-table-column align="center" prop="space_transform" label="场景" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" label="操作" width="100">
                        <template slot-scope="scope">
                            <div @click="clickDelItem(scope.row)" class="del-item">移除</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleSelectChange"
                        :current-page.sync="selectPage"
                        :page-size="15"
                        layout="total, prev, pager, next"
                        :total="selectTotal">
                    </el-pagination>
                </div>
            </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import printView from "../ProductManagement/components/printView.vue"
export default {
    components:{
        printView
    },
    mixins:[pageMixin],
    data () {
        return {
            isAddScenarios: false,
            editLoading: false,
            selectProduct: [],
            tableProduct: [],
            selectPage: 0,
            selectTotal: 0,
            pageMixin_index:'productList',
            keyword: '',
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            type_id: '',
            size_id: '',
            scene_id: '',
            typeOptions: [],
            sizeOptions: [],
            sceneOptions: [],
            btnLoading: false,
            printer: {
                id: '#printer',
                popTitle: "&nbsp", // 打印配置页上方标题
                extraHead: '当前第1页',
                beforeOpenCallback (vue) {
                    vue.printLoading = true;
                    vue.btnLoading = true;
                },
                openCallback (vue) {
                    vue.printLoading = false;
                    vue.btnLoading = false;
                }
            },
            wideOptions: [3,4],
            highOptions: [3,4],
            size: {
                wide: 3,
                high: 3
            }
        }
    },
    mounted () {
        this.getGoodsList();
        this.getTypeGoodsList();
        this.getSizeGoodsList();
        this.getSceneGoodsList();
    },
    methods: {
        getRowKeys(row) {
            return row.id;
        },
        clickDelItem(row){
            let list = this.$refs.tableData.selection;
            list.forEach((v,i)=>{
                if(row.id == v.id){
                    list.splice(i, 1);
                }
            })
            this.selectProduct = list;
            let arr = []
            if(list.length>15){
                arr = this.selectProduct.slice((this.selectPage-1)*15,this.selectPage*15);
            }else{
                arr = this.selectProduct
            }
            this.selectTotal--;
            this.tableProduct = arr;
            this.$refs.printView.setValue(this.tableProduct)
        },
        // 选中分页
        handleSelectChange(val){
            this.selectPage = val;
            this.tableProduct = this.selectProduct.slice((this.selectPage-1)*15,this.selectPage*15);
            this.$refs.printView.setValue(this.tableProduct)
        },
        clickSelect(){
            if(this.selectProduct.length>0){
                this.isAddScenarios = true;
            }else{
                this.$message.warning('请先选择打印的产品！')
            }
        },
        handleClose(){
            this.isAddScenarios = false
        },
        // 选择打印的产品
        handleSelectionChange(e){
            this.selectProduct = e
            this.selectTotal = e.length;
            this.tableProduct = this.selectProduct.slice(0,15)
            // this.tableProduct.forEach(v=>{
            //     let url = v.qrcode_link.split('data=')
            //     v.qrcode_link = url[0]+'data='+ encodeURIComponent(url[1]);
            // })
            this.$refs.printView.setValue(this.tableProduct,this.size)
        },
        input1(){
            this.$refs.printView.setValue(this.tableProduct,this.size)
        },
        input2(){
            this.$refs.printView.setValue(this.tableProduct,this.size)
        },
        // 使用场景筛选
        clickSceneChange(){
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 尺寸筛选
        clickSizeChange(){
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 系列筛选
        clickTypeChange(){
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 获取数据列表
        getGoodsList(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                type: 1,
                type_id: this.type_id,
                size_id: this.size_id,
                scene_id: this.scene_id
            }
            this.loading = true;
            common.connect('/distributorpcv1/goods/index',params,(res)=>{
                res.data.list.forEach(v => {
                    v.contents = JSON.parse(v.contents);
                    v.space_transform = v.space_transform.join(',');
                    v.type_transform = v.type_transform.join(',');
                    v.size_transform = v.size_transform.join(',')
                    let url = v.qrcode_link.split('data=')
                    v.qrcode_link = url[0]+'data='+ encodeURIComponent(url[1]);
                });
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 获取产品系列
        getTypeGoodsList(){
            common.connect('/distributorpcv1/goods/typeConditions',{put_on: true},(res)=>{
                this.typeOptions = res.data;
            })
        },
        // 获取产品尺寸
        getSizeGoodsList(){
            common.connect('/distributorpcv1/goods/sizeConditions',{put_on: true},(res)=>{
                this.sizeOptions = res.data;
            })
        },
        // 获取产品使用场景
        getSceneGoodsList(){
            common.connect('/distributorpcv1/goods/sceneConditions',{put_on: true},(res)=>{
                this.sceneOptions = res.data;
            })
        },
        clickSearch(){
            this.currentPage = 1;
            this.getGoodsList();
        },
        handleCurrentChange(val){
            this.printer.extraHead = '当前第'+val+'页'
            this.currentPage = val;
            this.getGoodsList();
        }
    },
}
</script>

<style lang='scss'>
.productList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .select-item{
                    margin-right: 15px;
                    margin-bottom: 10px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 10px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
            .sw-right{
                width: 240px;
                margin-bottom: 10px;
                display: flex;
                justify-content: right;
                align-items: center;
            }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .main-body {
        display: none;
    }

    .dialog-wrap{
        width: 1200px;
        padding-left: 20px;
        overflow: hidden;
        position: relative;
        .title{
            font-size: 16px;
            color: #000;
            margin-top: -5px;
            padding-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .setting-dimensions{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 30px;
                .sd-title{
                    font-size: 14px;
                    color: #333;
                    margin-right: 20px;
                }
                .tag{
                    padding: 0 10px;
                    font-size: 18px;
                    color: #000;
                }
                .sd-item{
                    display: flex;
                    align-items: center;
                    .uni{
                        font-size: 14px;
                        padding: 0 5px;
                    }
                    .unit{
                        font-size: 14px;
                        color: #666;
                        margin-right: 5px;
                    }
                    .el-input{
                        width: 50px;
                    }
                    .el-select__caret{
                        display: none;
                    }
                    .el-input__inner{
                        width: 50px;
                        height: 30px;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-bottom: 40px;
            padding-right: 20px;
            .del-item{
                cursor: pointer;
                color: #f56c6c;
            }
            .pagination-wrap{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 10px;
                position: absolute;
                bottom: 0;
                right: 10px;
                background: #fff;
                z-index: 99;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
